


















 import Storage from "../../util/Storage"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import { Vue,Component } from "vue-property-decorator"
 import { SetUserData,GetUserData } from "../../Api/Basics/index"
 import { Notify } from "vant"
 import ZoomPage from "@/impView/PageSafety";

interface SetNameType<T=string>{
    value : T;
    oldName:string;
    userId:string|number;
    token:string;
    LoadingShow:boolean;

    handleSetUpData():void;
    handleError(data:string):void;
}

 @Component({ name:"UserSetName",components:{ HeadTop } })
 export default class UserSetName extends ZoomPage implements SetNameType{
     value = ""
     oldName = "";
     LoadingShow = true;
     userId = "";
     token = "";
     mounted() {
        this.LoadingShow = false
        let UserData = Storage.GetData_ && JSON.parse( Storage.GetData_("UserData") )
        this.value =  UserData.name || UserData.nickName
        this.oldName = this.value
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
        this.token = Storage.GetData_ && Storage.GetData_("token")
     }

     handleSetUpData(){
         if( !this.value ){ this.handleError("请输入昵称") ;return; }
         let reg = / /g;
        //判断 是否 存在空格
         if( this.value.match(reg)?.length! > 2  ){ this.handleError("请输入昵称 超出两个空格");return; }
        //判断是否重复
         if( this.value == this.oldName ){ this.handleError("请输入昵称和之前昵称重复") ; return; }
         this.LoadingShow = true
         new Promise((reslove,resject)=>{
            SetUserData({
                userId:this.userId,
                token:this.token
            },{
                name:this.value,
                nickName:this.value
            }).then( (res:any)=>{
                console.log(res);
                if( res.message.code === "200" ){
                    reslove( true )
                }else{
                    resject(res)
                }
            } )
         }).then((res:any)=>{
             GetUserData({
                 userId:this.userId,
                 token:this.token
             }).then((res:any)=>{
                 console.log( res )
                 this.LoadingShow = false
                 if( res.message.code === "200" ){
                    this.value = res.data.name || res.data.nickName
                    Storage.SetData_ && Storage.SetData_("UserData",JSON.stringify( res.data ))
                 }else{
                     this.handleError(res.message.msg)
                 }
             })
         }).catch( (cat:any)=>{
             this.LoadingShow = false
             this.handleError(cat.message.msg)
         })
     }

     handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
 }
