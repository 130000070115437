import { render, staticRenderFns } from "./UserSetName.vue?vue&type=template&id=30243fee&scoped=true&"
import script from "./UserSetName.vue?vue&type=script&lang=ts&"
export * from "./UserSetName.vue?vue&type=script&lang=ts&"
import style0 from "./UserSetName.vue?vue&type=style&index=0&id=30243fee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30243fee",
  null
  
)

export default component.exports